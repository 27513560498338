import Lottie from "react-lottie";
import animationData from "../../../assets/1_animation_data.json";
type Size = "small" | "medium";

const S_SIZE = 70;
const M_SIZE = 144;

interface Props {
  size?: Size | number;
}

export const MovingOne = ({ size = "medium" }: Props) => {
  const s =
    typeof size === "string" ? (size === "small" ? S_SIZE : M_SIZE) : size;

  return (
    <Lottie
      options={{ animationData }}
      height={s}
      width={s}
      isClickToPauseDisabled={true}
    />
  );
};
